@charset 'utf-8';

// ==========================================================================
// Common
// ==========================================================================
@import 'common/normalize';
@import 'common/mixins';
@import 'common/colors';

:root {
	--ease: cubic-bezier(.48,.17,.33,.99);
}

html,
body {
	width: 100vw;
	min-height: 100vh;
}

body {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	font-family: -apple-system, Helvetica, Arial, sans-serif;
	color: $grey-l;
	background: $graphite;
	line-height: 1.625em;

	@include breakpoint(">768px") {
		align-items: flex-end;
		align-content: flex-end;
	}
}

a {
	position: relative;
	color: $blue;
	text-decoration: none;
	transition: .25s all ease;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: -2px;
		width: 0%;
		height: 1px;
		background: $blue-h;
		transition: .4s all ease;
	}

	&:hover,
	&:focus {
		color: $blue-h;

		&:after {
			width: 100%;
		}	
	}
}

h1, h2, h3 {
	margin: 0 0 1em;
	color: $white;
	line-height: 1em;
	letter-spacing: -1px;
}

.wrap {
	padding-top: 8%;
}

.main {
	position: relative;
	max-width: 640px;
	padding: 0 0 6rem 8rem;

	@include breakpoint("<768px") {
		max-width: 100%;
		padding: 6% 8%;
	}

	h1 {
		position: relative;
		font-size: 34px;

		&:after {
			content: '';
			position: absolute;
			bottom: -20px;
			left: 0;
			width: 52px;
			height: 5px;
			background: $white;
		}
	}
	h2 {
		font-size: 7vw;
		margin-bottom: .5em;

		@include breakpoint(">1200px") {
			font-size: 84px;
		}
		@include breakpoint("<640px") {
			font-size: 40px;
		}
	}

	h1, h2, h3 {
		animation: slideIn 0.8s var(--ease);
	}

	p {
		animation: fadeIn 1.2s var(--ease);
	}

	p:last-of-type {
		margin-bottom: 0;
	}
}

.side {
	width: 100%;
	padding: 6% 8%;
	font-family: 'PT Mono', "Courier New", Courier, Consolas, monospace;
	font-size: .8em;
	line-height: 1.5em;
	color: $grey;
	animation: fadeIn 1.4s var(--ease);

	@include breakpoint(">768px") {
		position: absolute;
		bottom: 20%;
		left: 5.5rem;
		padding: 0;
		transform: rotate(-90deg);
		transform-origin: left bottom;
	}

	.triangle {
		position: absolute;
		top: 6px;
		left: 340px;
		transform: rotate(90deg);
		transform-origin: 50% 18px;

		@include breakpoint("<768px") {
			float: left;
			position: static;
			margin-top: 5px;
			transform: rotate(0deg);
		}

		&.animated {
			animation: rotateV 2s 1;

			@include breakpoint("<768px") {
				animation: rotateH 2s 1;
			}
		}
	}

	p {
		@include breakpoint("<768px") {
			margin-left: 52px;
		}
	}

	span {
		
		@include breakpoint("<640px") {
			display: none;
		}
	}

}

	.contain .side {
		position: fixed;
		top: 64%;

		@include breakpoint("<768px") {
			position: static;
		}
	}
	@media (min-height: 1080px) {
		.side {
			bottom: 14%;
		}
	}

.bottom {
	padding: 0 0 6% 12%;

	@include breakpoint(">768px") {

	}

	@include breakpoint("<640px") {
		padding: 3% 8% 5%;
	}
}

.circle {
	position: fixed;
	top: 8%;
	right: 0px;
	z-index: -1;
	overflow: hidden;
	width: 120px;
	height: 408px;

	@include breakpoint("<640px") {
		top: 4%;
		width: 80px;
		opacity: 0.6;
	}

	.svg-circle {
		animation: pulse 16s infinite 2s;
	}
}

@keyframes slideIn {
	0% {
		opacity: 0;
		transform: translate3d( 0, -20px, 0 );
	}
	100% {
		opacity: 1;
		transform: translate3d( 0, 0, 0 );
	}
}

@keyframes fadeIn {
	  0% { opacity: 0; }
	 50% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes fadeInDelayedSide {
	  0% { opacity: 0; }
	 30% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes pulse {
	 0% { fill: $blue; }
	50% { fill: $green;	}
}

@keyframes rotateV {
	to { transform: rotate(450deg); }
}

@keyframes rotateH {
	to { transform: rotate(360deg); }
}