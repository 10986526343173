html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/**
 * Mixins
 */
/**
 * Breakpoint
 */
:root {
  --ease: cubic-bezier(.48,.17,.33,.99); }

html,
body {
  width: 100vw;
  min-height: 100vh; }

body {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  font-family: -apple-system, Helvetica, Arial, sans-serif;
  color: #9CACB5;
  background: #3D4347;
  line-height: 1.625em; }
  @media (min-width: 769px) {
    body {
      align-items: flex-end;
      align-content: flex-end; } }

a {
  position: relative;
  color: #3EB5D6;
  text-decoration: none;
  transition: .25s all ease; }
  a:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0%;
    height: 1px;
    background: #7dcde3;
    transition: .4s all ease; }
  a:hover, a:focus {
    color: #7dcde3; }
    a:hover:after, a:focus:after {
      width: 100%; }

h1, h2, h3 {
  margin: 0 0 1em;
  color: #FFFFFF;
  line-height: 1em;
  letter-spacing: -1px; }

.wrap {
  padding-top: 8%; }

.main {
  position: relative;
  max-width: 640px;
  padding: 0 0 6rem 8rem; }
  @media (max-width: 768px) {
    .main {
      max-width: 100%;
      padding: 6% 8%; } }
  .main h1 {
    position: relative;
    font-size: 34px; }
    .main h1:after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 52px;
      height: 5px;
      background: #FFFFFF; }
  .main h2 {
    font-size: 7vw;
    margin-bottom: .5em; }
    @media (min-width: 1201px) {
      .main h2 {
        font-size: 84px; } }
    @media (max-width: 640px) {
      .main h2 {
        font-size: 40px; } }
  .main h1, .main h2, .main h3 {
    animation: slideIn 0.8s var(--ease); }
  .main p {
    animation: fadeIn 1.2s var(--ease); }
  .main p:last-of-type {
    margin-bottom: 0; }

.side {
  width: 100%;
  padding: 6% 8%;
  font-family: 'PT Mono', "Courier New", Courier, Consolas, monospace;
  font-size: .8em;
  line-height: 1.5em;
  color: #69747A;
  animation: fadeIn 1.4s var(--ease); }
  @media (min-width: 769px) {
    .side {
      position: absolute;
      bottom: 20%;
      left: 5.5rem;
      padding: 0;
      transform: rotate(-90deg);
      transform-origin: left bottom; } }
  .side .triangle {
    position: absolute;
    top: 6px;
    left: 340px;
    transform: rotate(90deg);
    transform-origin: 50% 18px; }
    @media (max-width: 768px) {
      .side .triangle {
        float: left;
        position: static;
        margin-top: 5px;
        transform: rotate(0deg); } }
    .side .triangle.animated {
      animation: rotateV 2s 1; }
      @media (max-width: 768px) {
        .side .triangle.animated {
          animation: rotateH 2s 1; } }
  @media (max-width: 768px) {
    .side p {
      margin-left: 52px; } }
  @media (max-width: 640px) {
    .side span {
      display: none; } }

.contain .side {
  position: fixed;
  top: 64%; }
  @media (max-width: 768px) {
    .contain .side {
      position: static; } }

@media (min-height: 1080px) {
  .side {
    bottom: 14%; } }

.bottom {
  padding: 0 0 6% 12%; }
  @media (max-width: 640px) {
    .bottom {
      padding: 3% 8% 5%; } }

.circle {
  position: fixed;
  top: 8%;
  right: 0px;
  z-index: -1;
  overflow: hidden;
  width: 120px;
  height: 408px; }
  @media (max-width: 640px) {
    .circle {
      top: 4%;
      width: 80px;
      opacity: 0.6; } }
  .circle .svg-circle {
    animation: pulse 16s infinite 2s; }

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeInDelayedSide {
  0% {
    opacity: 0; }
  30% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes pulse {
  0% {
    fill: #3EB5D6; }
  50% {
    fill: #3AC5AC; } }

@keyframes rotateV {
  to {
    transform: rotate(450deg); } }

@keyframes rotateH {
  to {
    transform: rotate(360deg); } }
