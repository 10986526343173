/**
 * Mixins
 */

@mixin filter($filter-type,$filter-amount) {
	filter: $filter-type+unquote('(#{$filter-amount})');
}

@mixin placeholder {
	::-webkit-input-placeholder {@content}
	:-moz-placeholder           {@content}
	::-moz-placeholder          {@content}
	:-ms-input-placeholder      {@content}  
}

@mixin center($position) {

	@if $position == both {
		top: 35%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@else if $position == vertical {
		top: 35%;
		transform: translatey(-50%);
	}

	@else if $position == horizontal {
		left: 50%;
		transform: translatex(-50%);
	}

	@else if $position == clear {
		top: auto;
		left: auto;
		transform: translate(0, 0);
	}
}

/**
 * Breakpoint
 */
$breakpoints: 375px, 640px, 768px, 1200px, 1440px;

@mixin breakpoint( $size ){
	@if type-of($size) == string {
	  $approved-value: 0;
		@each $breakpoint in $breakpoints {
			$and-larger: ">" + $breakpoint;
			$and-smaller: "<" + $breakpoint;

			@if $size == $and-smaller {
				$approved-value: 1;
				@media ( max-width: $breakpoint ) {
					@content;
				}
			}
			@else {
				@if $size == $and-larger {
					$approved-value: 2;
					@media ( min-width: $breakpoint + 1 ) {
						@content;
					}
				}
				@else {
					@each $breakpoint-end in $breakpoints {
						$range: $breakpoint + "-" + $breakpoint-end;
						@if $size == $range {
							$approved-value: 3;
							@media ( min-width: $breakpoint + 1 ) and ( max-width: $breakpoint-end ) {
								@content;
							}
						}
					}
				}
			}
		}
		@if $approved-value == 0 {
			$sizes: "";
			@each $breakpoint in $breakpoints {
				$sizes: $sizes + " " + $breakpoint;
			}
			// TODO - change this to use @error, when it is supported by node-sass
			@warn "ERROR in breakpoint( #{ $size } ): You can only use these sizes[ #{$sizes} ] using the following syntax [ <#{ nth( $breakpoints, 1 ) } >#{ nth( $breakpoints, 1 ) } #{ nth( $breakpoints, 1 ) }-#{ nth( $breakpoints, 2 ) } ]";
		}
	}
	@else {
		$sizes: "";
		@each $breakpoint in $breakpoints {
			$sizes: $sizes + " " + $breakpoint;
		}
		// TODO - change this to use @error, when it is supported by node-sass
		@warn "ERROR in breakpoint( #{ $size } ): Please wrap the breakpoint $size in parenthesis. You can use these sizes[ #{$sizes} ] using the following syntax [ <#{ nth( $breakpoints, 1 ) } >#{ nth( $breakpoints, 1 ) } #{ nth( $breakpoints, 1 ) }-#{ nth( $breakpoints, 2 ) } ]";
	}
}

// clearfix

@mixin clearfix() {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}